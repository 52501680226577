/*
	Notifcation: {
		icon {
			src,
			alt
		},
		message,
		duration,
		type,
		id
	}
*/
App.Components.Notifications = class Accordion {

	constructor(element, options) {
		console.log("Notifications: constructor() called");
		this.element = element || document.querySelector("#notifications");
		this.options = options;

		this.bindEvents();
	}

	bindEvents() {
		console.log("Notifications: bindEvents() called");
		let self = this;
	}

	testNotification() {
		console.log("Notifications: testNotification() called");
		let self = this;
	}

	notify(notification) {
		console.log("Notifications: notify() called");
		let self = this;
		if (!notification) {
			return false;
		}

		let template = `<div class="-new"><div class="flex -vcenter"><div class="-static"><img src="${notification.icon.src}" alt="${notification.icon.alt}" /></div><div>${notification.message}</div></div></div>`;
		this.element.insertAdjacentHTML("beforeend", template);

		let el = this.element.querySelector(".-new");
		el.classList.remove("-new");

		let timeout = window.setTimeout(() => {
			self.hideNotification(el);
		}, 7250);

		el.addEventListener("mouseenter", function (e) {
			clearTimeout(timeout);
		});

		el.addEventListener("mouseleave", function (e) {
			timeout = window.setTimeout(() => {
				self.hideNotification(el);
			}, 7250);
		});
		return true;
	}

	hideNotification(notification) {
		if (!notification) {
			return;
		}

		notification.classList.add("-old");
		window.setTimeout(() => {
			notification.style.display = "none";
		}, 250);
	}
}