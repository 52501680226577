App.Components.Carousel = class Carousel {

	constructor(element, options) {
		this.element = element;
		// Defaults
		this.options = {
			continuous: true,
			stack: true,
			defaultShowTime: 7000,
			autplay: true
		}
		Object.assign(this.options, options);

		this.stage = element.querySelector(".stage");
		this.scenes = element.querySelectorAll(".stage > div");
		this.next = element.querySelector(".next") || null;
		this.prev = element.querySelector(".previous") || null;
		this.currentScene = 1;
		this.lastScene = this.scenes.length;

		if (!this.options.pagination) {
			this.options.pagination = element.querySelector(".pagination") || null;
		}

		if (!this.options.paginationActiveClass) {
			this.options.paginationActiveClass = "-active";
		}

		// Reference to timers, intervals, and events
		this.intervals = [];
		this.options.paginationHandlers = [];

		this.bindEvents();
	}

	// Called when carousel content changes
	rebind() {
		// Initial Values
		this.currentScene = 1;
		this.scenes = this.element.querySelectorAll(".stage > div");
		this.lastScene = this.scenes.length;

		// Remove old gallery handlers
		if (this.options.pagination) {
			let pages = this.options.pagination.children;
			for (let i = pages.length - 1; i >= 0; i--) {
				pages.item(i).removeEventListener("click", this.options.paginationHandlers[i]);
			}
			// Set up current gallery handlers
			this.setupPagination();
		}

	}

	// Called on creation
	bindEvents() {
		let self = this;

		// Manual next event
		if (this.next) {
			this.next.addEventListener("click", function (e) {
				self.gotoNextScene();
				self.options.autoplay = false;
				[].map.call(self.intervals, function (interval) {
					window.clearInterval(interval);
				});
				self.updatePagination();
			});
		}

		// Manual previous event
		if (this.prev) {
			this.prev.addEventListener("click", function (e) {
				self.gotoPreviousScene();
				self.options.autoplay = false;
				[].map.call(self.intervals, function (interval) {
					window.clearInterval(interval);
				});
				self.updatePagination();
			});
		}

		// Pagination
		this.setupPagination();

		// Autoplay
		if (this.options.autoplay) {
			this.intervals.push(window.setInterval(self.gotoNextScene.bind(this), this.options.defaultShowTime));
		}

	}

	// Pagination setup, stores all event handlers to remove if we rebind later
	setupPagination() {
		let self = this;
		this.options.paginationHandlers = [];

		if (this.options.pagination) {
			let pages = this.options.pagination.children;
			for (let i = 0; i < pages.length; i++){
				let handler = function (e) { self.gotoScene(i + 1); };
				this.options.paginationHandlers.push(handler);
				pages.item(i).addEventListener("click", handler);
			}
		}
	}

	gotoScene(i) {
		let self = this;
		this.pauseVideo();
		this.stage.style.transform = "translateX(-" + ((i) * 100 - 100) + "%)";
		this.currentScene = i;
		this.startVideo();
		this.updatePagination();
	}

	pauseVideo() {
		let self = this,
			video = this.scenes[this.currentScene - 1].querySelector("video");

		if (video) {
			video.pause();
		}
	}

	startVideo() {
		let self = this,
			video = this.scenes[this.currentScene - 1].querySelector("video");

		if (video) {
			video.play();
		}
	}

	gotoNextScene() {
		console.log("gotoNextScene called")

		if (this.currentScene == this.lastScene) {

			if (!this.options.continuous) {
				return;
			} else {
				this.currentScene = 1;
				// if (this.options.stack) {
				// 	gotoNextSceneStack();
				// 	return;
				// }
				this.stage.style.transform = "translateX(0)";
				return;
			}
		}

		this.currentScene++;
		this.gotoScene(this.currentScene);
		return;
	}

	gotoPreviousScene() {
		console.log("gotoPreviousScene called")

		if (this.currentScene == 1) {
			this.currentScene = this.lastScene;
			this.stage.style.transform = "translateX(-" + ((this.currentScene - 1) * 100) + "%)";
			return;
		}

		this.currentScene--;
		this.gotoScene(this.currentScene);
		return;
	}

	updatePagination() {

		if (!this.options.pagination) {
			return;
		}

		// Remove highlighted active pagination item
		let activeEl = this.options.pagination.querySelector("." + this.options.paginationActiveClass[0]);
		if (activeEl) {
			activeEl.classList.remove(...this.options.paginationActiveClass);
		}

		// Apply highlight to active pagination item
		let newActiveEl = this.options.pagination.children.item(this.currentScene - 1); // 0-based index
		if (newActiveEl) {
			newActiveEl.classList.add(...this.options.paginationActiveClass);
		}
	}
}