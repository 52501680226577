App.Components.Slides = class Slides {

	constructor(element, options) {
		this.element = element,
		this.options = options,
		this.slideContainers = document.querySelectorAll(".slides"),
		this.slides = document.querySelectorAll(".slides > div"),
		this.slidesOffsets = [],
		this.scrollPreviousY = 0,
		this.scrollDirectionDown = false;
		this.scrollContainer = (options.scrollContainer) ? options.scrollContainer : window;
		this.createSlideHeightContainers();
		this.setZIndexes();
		this.bindEvents();
	}

	createSlideHeightContainers() {
		console.log("Found " + this.slideContainers.length + " slide container(s).")
		let self = this;

		[].map.call(this.slideContainers, function (container) {
			let newElement = document.createElement("div");
			newElement.style.height = self.calculateContainerHeight(container);
			newElement.classList.add("slides-height");
			container.parentElement.insertBefore(newElement, container.nextSibling); // https://developer.mozilla.org/en-US/docs/Web/API/Node/insertBefore
		});
	}

	calculateContainerHeight(container) {
		let self = this;
		let height = 0;

		this.slidesOffsets = [];
		[].map.call(container.children, function (slide) {
			height += slide.clientHeight;
			self.slidesOffsets.push(height);
		});

		return height + "px";
	}

	setZIndexes() {
		let i = 1;
		[].map.call(this.slides, function(slide) {
			slide.style.zIndex = i;
			i--;
		});
	};

	bindEvents() {
		let self = this;

		this.scrollContainer.addEventListener("scroll", function (e) {
			self.onScroll(e);
		}.bind(this), false);
	}

	onScroll(e) {
		let self = this,
			y = this.scrollContainer.scrollTop,
			i = 0,
			nextSlide,
			scrollingDown = this.scrollPreviousY < y ? true : false;

		console.log(y);
		if (scrollingDown) {
			[].map.call(this.slidesOffsets, function (slideOffset) {
				i++;
				if (y >= slideOffset)
					nextSlide = i;
			});
			this.slides.item(nextSlide).classList.remove("-fixed");
		} else {
			[].map.call(this.slidesOffsets, function (slideOffset) {
				if (y <= slideOffset) {
					nextSlide = i;
					return;
				}
				i++;
			});
			this.slides.item(nextSlide + 1).classList.add("-fixed");
		}

		this.scrollPreviousY = y;
	}
};