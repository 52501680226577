App.Components.Checkbox = class Checkbox {

	constructor(element) {
		if (!element) return false;

		console.log("Initiating Enhanced Checkbox")
		console.log(element)

		/*
		 * Steps:
		 *	- Reference original element
		 *	- Hide original element
		 *	- Create enhanced element, place in DOM
		 *	- Bind events to enhanced element
		 *	- Maintain checked property for original element
		 */
		this.original = element;
		this.label = this.original.parentElement;
		this.label.style.display = "none";
		this.data = {
			value: element.value || 1,
			name: element.name || "",
			isChecked: element.checked
		}
		this.element = this.createElement();
		this.bindEvents();
	}
	
	createElement() {
		let node = document.createElement("div");
		node.classList.add("checkbox");

		let template = `<span></span> ${this.label.textContent}`;
		node.innerHTML = template;

		return this.label.parentElement.insertBefore(node, this.label);
	}

	bindEvents() {
		let self = this;

		this.element.addEventListener("click", function (e) {
			let isChecked = self.element.classList.toggle("-active");
			self.data.isChecked = isChecked;
			self.original.checked = isChecked;
		});
	}
}