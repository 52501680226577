/*
	Rate: {
		title
	}
*/
App.Components.Rate = class Rate {

	constructor(options) {
		console.log("Rate: constructor() called");
		this.element = null;
		this.options = options;
		this.showRatingModal();
		this.wireCarousel();
		this.bindEvents();
	}

	bindEvents() {
		console.log("Rate: bindEvents() called");
		let self = this;
		return true;
	}

	unbindEvents() {
		console.log("Rate: unbindEvents() called");
		return true;
	}

	showRatingModal() {
		console.log("Rate: showRatingModal() called");
		let template =
			`
<div class="-wrap-40-">
	<p>
		You are rating ${this.options.title}
	</p>
	<div class="carousel">
		<div class="stage">
			<div>
				<h1 class="title">
					Do you own this game?
				</h1>
				<div class="flex -haround -bauto">
					<button class="-bg-primary1" value="0">No</button>
					<button class="-bg-primary2" value="1">Yes</button>
				</div>
			</div>
			<div>
				<h1 class="title">
					Which platform was this played on?
				</h1>
				<div class="flex -haround -bauto">
					<select>
						<option>Select a Platform</option>
						<option>Xbox One</option>
					</select>
				</div>
			</div>
			<div>
				<h1 class="title">
					How would you rate this game?
				</h1>
				<div class="flex -haround -bauto _score">
					<input type="number" min="0" max="10" step="0.1" value="5" />
					<button class="-bg-secondary1">It Was Okay</button>
				</div>
			</div>
			<div>
				<h1 class="title">
					Which categories did the game fall short in?
				</h1>
				<div class="grid -xs-2 -s-3 -m-4 -l-6 -text-center">
					<div>
						<img src="/assets/media/v0.1.0/gameplay.svg" alt="Gameplay" />
						<span class="title">Gameplay</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/graphics.svg" alt="Graphics" />
						<span class="title">Graphics</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/multiplayer_v1.svg" alt="Multiplayer" />
						<span class="title">Multiplayer</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/music.svg" alt="Music" />
						<span class="title">Music</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/replay.svg" alt="Replay" />
						<span class="title">Replay</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/story_v1.svg" alt="Story" />
						<span class="title">Story</span>
					</div>
				</div>
			</div>
			<div>
				<h1 class="title">
					Which categories did the game do best in?
				</h1>
				<div class="grid -xs-2 -s-3 -m-4 -l-6 -text-center">
					<div>
						<img src="/assets/media/v0.1.0/gameplay.svg" alt="Gameplay" />
						<span class="title">Gameplay</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/graphics.svg" alt="Graphics" />
						<span class="title">Graphics</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/multiplayer_v1.svg" alt="Multiplayer" />
						<span class="title">Multiplayer</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/music.svg" alt="Music" />
						<span class="title">Music</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/replay.svg" alt="Replay" />
						<span class="title">Replay</span>
					</div>
					<div>
						<img src="/assets/media/v0.1.0/story_v1.svg" alt="Story" />
						<span class="title">Story</span>
					</div>
				</div>
			</div>
		</div>
		<div class="next"><img src="/assets/media/icons/right.svg" /></div>
		<div class="prev"><img src="/assets/media/icons/left.svg" /></div>
	</div>
</div>
			`;

		let modal = document.createElement("div");
		modal.id = "modal-rate";
		modal.classList.add("modal");
		modal.innerHTML = template;

		let target = document.querySelector("#modals");
		if (!target) {
			target = document.createElement("div");
			target.id = "modals";
			document.querySelector("body").appendChild(target);
		}
		target.appendChild(modal);

		this.element = modal;
		if (!App.Modals) {
			App.Modals = new App.Components.Modal();
		}
		App.Modals.showModal(modal);
		// target.classList.add("-active");
		// modal.classList.add("-active");

		return true;
	}

	wireCarousel() {
		console.log("Rate: wireCarousel() called");

		let element = this.element.querySelector(".carousel");
		let carousel = new App.Components.Carousel(element, {
			autoplay: false
		});

		return true;
	}

}