App.Components.Select = class Select {

	constructor(element) {
		if (!element) return false;

		console.log("Initiating Enhanced Select")

		/*
		 * Steps:
		 *	- Reference original element, then hide
		 *	- Create enhanced element, place in DOM
		 *	- Bind events to enhanced element
		 *	- Maintain selected property for original element
		 */
		this.original = element;
		this.originalOptions = element.querySelectorAll("option");
		this.label = this.original.parentElement;
		this.label.style.display = "none";
		this.data = {
			value: element.value || 1,
			name: element.name || "",
			isChecked: element.checked
		}
		this.element = this.createElement();
		this.options = null;
		this.isMultiple = false;
		this.optionsSelected = [];
		this.bindEvents();
	}

	createElement() {
		let node = document.createElement("div");
		node.classList.add("select");
		let span = document.createElement("span");

		let options = document.createElement("ul");
		let i = 1;
		[].map.call(this.originalOptions, function (element) {
			if (i == 1) {
				span.innerText = `${element.textContent}`;
				node.appendChild(span);
				i++;
			} else {
				let option = document.createElement("li");
				option.innerText = `${element.textContent}`;
				options.appendChild(option);
			}
		});
		node.appendChild(options);

		return this.label.parentElement.insertBefore(node, this.label);
	}

	bindEvents() {
		let self = this;
		this.options = this.element.querySelectorAll("li");

		// Dropdown click
		this.element.addEventListener("click", function (e) {
			let isChecked = self.element.classList.toggle("-active");
			self.data.isChecked = isChecked;
			self.original.checked = isChecked;
		});

		// Option click
		[].map.call(this.options, function (option) {
			option.addEventListener("click", function (e) {
				e.preventDefault();
				e.stopPropagation();

				let isSelected = this.classList.toggle("-selected");

				if (isSelected) {
					if (!self.isMultiple) {
						self.optionsSelected = [];
					}
					self.optionsSelected.push(this);
				} else {
					let i = self.optionsSelected.indexOf(this);
					self.optionsSelected.splice(i, 1);
				}

				if (isSelected && !self.isMultiple) {
					self.element.classList.toggle("-active");
				}
				self.selectionsChanged();
			});
		});
		
	}

	selectionsChanged() {
		let self = this;

		// Toggle selected class
		[].map.call(this.options, function (option) {
			if (self.optionsSelected.indexOf(option) >= 0) {

			} else {
				option.classList.remove("-selected");
			}
		})

		// Set span text
		let span = this.element.querySelector("span");
		if(this.optionsSelected.length)
			span.innerText = this.optionsSelected[0].innerText;
		else
			span.innerText = this.originalOptions.item(0).innerText;
	}

	destroy() {
		console.log("destruction!!!!!")
	}
}