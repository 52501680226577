App.Components.Modal = class Modal {

	constructor(element, config) {
		this.options = {}
		this.element = element;
		this.modalContainer = document.querySelector("#modals"),
		this.backdrop = this.modalContainer.querySelector(".backdrop"),
		this.modals = this.modalContainer.querySelectorAll(".modal"),
		this.modalLinks = document.querySelectorAll("*[data-modal]");

		// Default option values
		if (config) {
			Object.assign(this.option, config);
		}

		// Bind all events
		this.bindEvents();
	}

	bindEvents() {
		let self = this;

		// Hook up modal close event on modal-container
		this.modalContainer.addEventListener("click", function (e) {
			let target = e.target || e.toElement;
			let parent = e.target.parentElement;
			let modal = self.modalContainer.querySelector(".-active");

			if (target != self.modalContainer &&
				target != self.backdrop &&
				(!target.classList.contains("_close") && (!parent.classList.contains("_close"))))
			{
				return;
			}

			e.preventDefault();

			self.closeModal(modal);
		});

		// Hook up events for modal windows
		[].map.call(this.modalLinks, function(link) {
			link.addEventListener("click", function (e) {
				e.preventDefault();

				// if (this.parentElement.classList.contains("-active"))
				// 	return;

				let modal = document.querySelector(link.dataset.modal);
				self.showModal(modal);

				if (link.dataset.focus != null) {
					let focusElement = modal.querySelector(link.dataset.focus);
					focusElement.focus();
					focusElement.select();
				}

			});
		});

		// Popstate event
		window.addEventListener("popstate", function (e) {
			self.popState(e);
		});

		return true;
	}

	showModal(modal) {
		if (!modal)
			return;

		// If modal has a `data-source` attribute, we will
		// copy source HTML to modal before showing
		// TODO does this have to copy everytime? Another data attribute?
		if (modal.dataset.source && !modal.dataset.shownBefore) {
            let source = document.querySelector(modal.dataset.source);
            if (source) {
                let clone = source.cloneNode(true);
                modal.appendChild(clone);
			}
		}

		// If a modal has a `data-init` attribute, we will
		// try to call that function before showing.
		// TODO: verify it's a function with typeof? Handle error?
        if (modal.dataset.init && !modal.dataset.shownBefore) {
            try {
                let call = modal.dataset.init;
                window[call](modal);
                // console.log(call);
                // console.log("should be rebound");
            } catch (e) {
				// silently go away please
			}
		}

		// This configures the light or dark backdrop
		if (modal.classList.contains("-light")) {
			this.modalContainer.classList.add("-light");
		}

		// Update history
		this.pushState();

		// Show the modal
		this.modalContainer.classList.add("-active");
		modal.classList.add("-active");
		document.querySelector("body").classList.add("-no-scroll");
        modal.dataset.shownBefore = true;

		// Start the video if available
		this.startVideo(modal);

		return;
	}

	closeModal(modal) {
		// console.log("Modal: closeModal() called");
		let self = this;

		// Pause the video if applicable
		this.pauseVideo(modal);

		// Hide modal
		this.modalContainer.classList.remove("-active");
		this.modalContainer.classList.remove("-light");
		modal.classList.remove("-active");
		document.querySelector("body").classList.remove("-no-scroll");

		// Remove history state
		this.undoState();

		return true;
	}

	undoState() {
		if (window.history && window.history.state) {
			if (window.history.state.isModalOpen)
				window.history.back();
		}
	}

	pushState() {
		// console.log("Modal: pushState() called");
		if (window.history) {
			window.history.replaceState({ isModalOpen: false }, "Modal Open", window.location.pathname);
			window.history.pushState({ isModalOpen: true }, "", window.location.pathname);
		}
	}

	popState(e) {
		// console.log("Modal: popState() called");

		if (window.history) {
			let self = this;

			if (window.history.state) {
				let isModalOpen = window.history.state.isModalOpen;
				if (!isModalOpen) {
					self.closeModal();
				}
			}
		}
	}

	startVideo(modal) {
		if (!modal) return;

		let video = modal.querySelector("video");
		if (video) {
			video.play();
		}
	}

	pauseVideo(modal) {
		if (!modal) return;

		let video = modal.querySelector("video");
		if (video) {
			video.pause();
		}
	}
};