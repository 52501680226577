App.Components.Form = class Form {

	constructor(element, options) {
		this.element = element;
		this.options = {
			isSticky: true,
			isCheckboxEnhanced: true,
			isRadioEnhanced: true,
			isSelectEnhanced: true,
			isSwitchEnabled: true,
			isRangeEnabled: true
		};
		this.data = {};
		this.fieldElements = null;
		if (options != null) {

		}

		if (this.options.isSticky)
			this.initSticky();
		if (this.options.isCheckboxEnhanced)
			this.initCheckboxes();
		if (this.options.isRadioEnhanced)
			this.initRadios();
		if (this.options.isSelectEnhanced)
			this.initSelects();
	}

	/*
	 * FEATURE: Enhanced Checkboxes
	 */
	initCheckboxes() {
		console.log("Initiating checkboxes");
		let elements = this.element.querySelectorAll("input[type=checkbox]");
		console.log(elements);
		[].map.call(elements, function (element) {
			new App.Components.Checkbox(element);
		});
	}

	/*
	 * FEATURE: Enhanced Radios
	 */
	initRadios() {
		console.log("Initiating radios");
		let elements = this.element.querySelectorAll("input[type=radio]");
		console.log(elements);
		[].map.call(elements, function (element) {
			new App.Components.Radio(element);
		});
	}

	/*
	 * FEATURE: Enhanced Selects
	 */
	initSelects() {
		console.log("Initiating selects");
		let elements = this.element.querySelectorAll("select");
		console.log(elements);
		[].map.call(elements, function (element) {
			new App.Components.Select(element);
		});
	}

	/*
	 * FEATURE: Switches
	 */
	initSwitches() { }

	/*
	 * FEATURE: Ranges
	 */
	initRanges() { }

	/*
	 * FEATURE: Sticky Fill-in
	 *	- Saves form data to localStorage or cookie
	 *	- Loads form data, if available, from localStorage or cookie
	 *	- Destroys form data on submit event
	 */
	initSticky() {
		console.log("Initializing Sticky Feature");
		let self = this;

		this.loadFormData();

		this.fieldElements = document.querySelectorAll("input, textarea, select");
		[].map.call(this.fieldElements, function (field) {
			[].map.call(["change"], function (event) {
				field.addEventListener(event, function (e) {
					self.buildFormData();
				});
			});
		});
	}

	buildFormData() {
		let fields = this.fieldElements;
		let self = this;
		console.log(fields)
		for (let i = 0, l = fields.length; i < l; i++) {
			let field = fields.item(i);
			let data = {
				nameAttr: field.name,
				idAttr: field.id,
				typeAttr: field.type,
				value: field.value
			};

			switch (data.typeAttr) {
				case "password":
					break;
				case "text":
				case "number":
				case "textarea":
					self.data[data.nameAttr] = data.value;
					break;
				case "radio":
				case "checkbox":
					data.value = field.checked;
					self.data[data.nameAttr] = data.value;
					break;
				default:
					self.data[data.nameAttr] = data.value;
			}
		}

		this.saveFormData();
	}

	saveFormData() {
		console.log(this.data)
		window.localStorage.setItem(window.location.pathname + "-form", JSON.stringify(this.data));
	}

	loadFormData() {
		try {
			this.data = JSON.parse(window.localStorage.getItem(window.location.pathname + "-form")) || {};

			if (Object.keys(this.data).length == 0) return;

			for (let prop in this.data) {
				let element = document.getElementsByName(prop);
				if (element.length) {
					let value = this.data[prop];
					if (value == true || value == false) {
						element.item(0).checked = value;
					} else {
						element.item(0).value = value;
					}
				}
			}
		} catch (e) {
		}
	}

	/*
	 * Destroy and Cleanup
	 */
	destroy() {
		console.log("destruction!!!!!")
	}
}