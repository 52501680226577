App.Components.Tooltip = class Tooltip {

	constructor(element, options) {
		this.element = element || null;
		this.title = options.title || "";
		this.tip = options.tip || "";
		this.align = options.align || "right"
	}

	bindEvents() {
		let self = this;

		[].map.call(["hover", "focus"], function (event) {
			element.addEventListener(event, function (e) {
				self.showTooltip();
			});
		});
	}

	showTooltip() {

	}
}