App.Components.Tab = class Tab {

	constructor(element, options) {
		this.element = element;
		this.options = options;
		this.tabs = document.querySelectorAll(".tab");
		this.panels = document.querySelectorAll(".tab-panel");

		this.bindEvents();
	}

	bindEvents() {
		let self = this;

		// Hook up events for tabs
		[].map.call(this.tabs, function (tab) {
			tab.addEventListener("click", function (e) {

				if (this.classList.contains("-active"))
					return;

				self.showPanel(tab);
			});
		});

		// Hook up events for panels
		[].map.call(this.panels, function (panel) {

		});

	}

	showPanel(tabElement) {
		let panelCSSSelector = "";

		// Set active tab
		[].map.call(this.tabs, function (tab) {
			if (tab == tabElement) {
				tab.classList.add("-active");
				panelCSSSelector = tab.dataset.panel || null;
			} else {
				tab.classList.remove("-active");
			}
		})

		if (panelCSSSelector) {
			let panelElement = document.querySelector(panelCSSSelector);
			[].map.call(this.panels, function (panel) {
				if (panel == panelElement) {
					panel.classList.add("-active");
				} else {
					panel.classList.remove("-active");
				}
			});
		}
	}
}